import React, { useState, useEffect } from 'react';
import { BookingNewApi } from '../../types/interfaces';
import styles from './EventsList.module.scss';
import defaultPortrait from '../../images/portraits/ab-portratt.jpg';

interface Props {
  events: BookingNewApi[];
}

type MergedEmployee = {
  EmployeeName: string;
  EmployeeNo: string;
};

interface MergedEvent extends Omit<BookingNewApi, 'EmployeeName'> {
  Employees: MergedEmployee[];
}

const EventsList: React.FC<Props> = ({ events }: Props) => {
  const [mergedEvent, setMergedEvent] = useState<MergedEvent>();
  const [mergedStartDate, setMergedStartDate] = useState<Date>();
  const [mergedStartTime, setMergedStartTime] = useState<number | string>();
  const [mergedEndTime, setMergedEndTime] = useState<number | string>();
  const [mergedItemName, setMergedItemName] = useState<string>();

  console.log(defaultPortrait);

  useEffect(() => {
    let allEmployeesSameTime = false;
    const employeesArr: MergedEmployee[] = [];

    events.forEach(event => {
      if (
        event.StartDate === events[0].StartDate &&
        event.StartTime === events[0].StartTime
      ) {
        allEmployeesSameTime = true;
        employeesArr.push({
          EmployeeName: event.EmployeeName,
          EmployeeNo: event.EmployeeNo
        });
      } else {
        allEmployeesSameTime = false;
      }
    });

    if (allEmployeesSameTime) {
      const constructMergedEvent = {
        Employees: employeesArr,
        EndTime: events[0].EndTime,
        ItemName: events[0].ItemName,
        ProjectName: events[0].ProjectName,
        StartDate: events[0].StartDate,
        StartTime: events[0].StartTime,
        EmployeeNo: events[0].EmployeeNo,
        ProjectNo: events[0].ProjectNo,
        CustomerNo: events[0].CustomerNo,
        CustomerName: events[0].CustomerName,
        CustomerId: events[0].CustomerId
      };

      const constructMergedStartDate = new Date(constructMergedEvent.StartDate);

      setMergedEvent(constructMergedEvent);
      setMergedStartDate(constructMergedStartDate);
      setMergedStartTime(constructMergedEvent.StartTime);
      setMergedEndTime(constructMergedEvent.EndTime);
      setMergedItemName(constructMergedEvent.ItemName);
    }
  }, [events]);

  return !mergedEvent ? (
    <>
      <ul className={styles.list}>
        {events.map(event => {
          const startDate = new Date(event.StartDate);

          let src;

          console.log(
            `../../images/portraits/${
              event.EmployeeNo
            } - ${event.EmployeeName.split(' ')
              .slice(0, -1)
              .join(' ')}.jpg`
          );

          try {
            src = require(`../../images/portraits/${
              event.EmployeeNo
            } - ${event.EmployeeName.split(' ')
              .slice(0, -1)
              .join(' ')}.jpg`);
          } catch (err) {
            console.log('Portrait error: ', err);
            src = defaultPortrait;
          }

          return (
            <li className={styles.event} key={event.ProjectNo}>
              <div key={event.EmployeeName}>
                <img
                  className={styles.portrait}
                  src={src}
                  alt="Bild på personal"
                />
                <span key={event.EmployeeName}>{event.EmployeeName}</span>
              </div>
              <div className={styles.subInfoList}>
                <p>
                  Tjänst:{' '}
                  <span className={styles.subInfo}>{`${event.ItemName}`}</span>
                </p>
                <p>
                  Datum:{' '}
                  <span className={styles.subInfo}>
                    {startDate.toLocaleDateString('sv-SE')}
                  </span>
                </p>
                <p>
                  Prel. Starttid:{' '}
                  <span className={styles.subInfo}>{`${event.StartTime}`}</span>
                </p>
                <p>
                  Prel. Sluttid:{' '}
                  <span className={styles.subInfo}>{`${event.EndTime}`}</span>
                </p>
              </div>
            </li>
          );
        })}
      </ul>
      <p
        className={`${styles.finePrint} ${styles.subInfo} ${styles.mAuto} ${styles.textCenter}`}
      >
        Alla tider är ungefärliga och kan påverkas av oförutsedda händelser som
        sjukdom hos personal och förlängda restider.
      </p>
    </>
  ) : (
    <div className={styles.subInfoList}>
      <div className={styles.staffBox}>
        {mergedEvent.Employees.map(employee => {
          let src;

          try {
            src = require(`../../images/portraits/${
              employee.EmployeeNo
            } - ${employee.EmployeeName.split(' ')
              .slice(0, -1)
              .join(' ')}.jpg`);
          } catch (err) {
            src = defaultPortrait;
          }

          return (
            <div className={styles.singleStaffBox} key={employee.EmployeeNo}>
              <img
                className={styles.portrait}
                src={src}
                alt="Bild på personal"
              />
              <span key={employee.EmployeeName}>
                {`${employee.EmployeeName.split(' ')
                  .slice(0, -1)
                  .join(' ')}`}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.eventBox}>
        <p>
          Tjänst: <span className={styles.subInfo}>{`${mergedItemName}`}</span>
        </p>
        <p className={styles.mr2}>
          Datum:{' '}
          <span className={styles.subInfo}>
            {mergedStartDate && mergedStartDate.toLocaleDateString('sv-SE')}
          </span>
        </p>
        <p>
          Prel. starttid:{' '}
          <span className={styles.subInfo}>{`${mergedStartTime}`}</span>
        </p>
        <p>
          Prel. sluttid:{' '}
          <span className={styles.subInfo}>{`${mergedEndTime}`}</span>
        </p>
      </div>
      <p className={`${styles.finePrint} ${styles.subInfo}`}>
        Alla tider är ungefärliga och kan påverkas av oförutsedda händelser som
        sjukdom hos personal och förlängda restider.
      </p>
    </div>
  );
};

export default EventsList;
